import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import './aboutUsPageTop.scss';
import demoImgOriginal from './img/testSizeCode.jpg';
import demoImgPix from './img/testSizeCodePix.jpg';


const AboutUsPageTop: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="about-us-top">
            <div className='about-us-top__inner'>
                <div className="about-us-top__content">
                    <h1 className="about-us-top__content-title">
                        {t('aboutUs.headings.title')}
                    </h1>
                    <div className="about-us-top__content-description">
                        <p className="about-us-top__content-description-text">{t('aboutUs.descriptions.description_1')}</p>
                        <p className="about-us-top__content-description-text">{t('aboutUs.descriptions.description_2')}</p>
                    </div>
                    <h2 className="about-us-top__content-heading">
                        {t('aboutUs.headings.heading')}
                    </h2>
                    <div className='about-us-top__content-button btn'>
                        <Link to={'./upload'} className='about-us-top__button-link btn-primary'>
                            {t('buttons.tryItButton')}
                        </Link>
                    </div>
                </div>
                <div className="about-us-top__image">
                    <div className="about-us-top__image-container">
                        <img className="about-us-top__image-demo img-original" src={demoImgOriginal} alt="Original demo photo" />
                        <div className="about-us-top__image-demo-wrapper">
                            <img className="about-us-top__image-demo img-pix" src={demoImgPix} alt="Pixelated demo photo" />
                        </div>
                        <div className="about-us-top__image-demo-control">
                            <div className="about-us-top__image-demo-control-line"></div>
                            <div className="about-us-top__image-demo-control-wrapper">
                                <div className="about-us-top__image-demo-control-arrow">
                                    <svg className="about-us-top__image-demo-control-arrow-svg arrow-svg-right" height="15" width="15" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 15 15">
                                        <path fill="#FFFFFF" stroke="#FFFFFF" stroke-linecap="round" stroke-width="0" d="M4.5 1.9L10 7.65l-5.5 5.4"></path>
                                    </svg>
                                </div>
                                <div className="about-us-top__image-demo-control-arrow">
                                    <svg className="about-us-top__image-demo-control-arrow-svg arrow-svg-left" height="15" width="15" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 15 15">
                                        <path fill="#FFFFFF" stroke="#FFFFFF" stroke-linecap="round" stroke-width="0" d="M4.5 1.9L10 7.65l-5.5 5.4"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="about-us-top__image-demo-control-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPageTop;
