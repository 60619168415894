import { useTranslation } from "react-i18next";
import './aboutUsPageBottom.scss';

const AboutUsPageBottom: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="about-us-bottom">

        </div>
    )
}

export default AboutUsPageBottom;
